import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header = 
      <div className="main-heading-wrapper">
        <h1 className="main-heading">
        <Link to="/">ecu</Link>
      </h1>
      {/* navbar */}
      <nav className="navbar-wrapper">
        <a href="https://www.facebook.com/shohruh.ka" className="nav-item">
        <StaticImage
        className="nav-icon"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/facebook-logo.png"
        height={22}
        quality={95}
        alt="Facebook logo"
      />
      </a>

      <a href="https://www.instagram.com/shohruhak/" className="nav-item">
        <StaticImage
        className="nav-icon"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/instagram-logo.png"
        height={20}
        quality={95}
        alt="Instagram logo"
      />
      </a>

      <a href="https://www.youtube.com/channel/UCbJjUTqbGjtaItKO6loJdwA" className="nav-item">
        <StaticImage
        className="nav-icon"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/youtube-logo.png"
        height={15}
        quality={95}
        alt="Youtube logo"
      />
      </a>
      </nav>
      </div>


  return (
    <div  data-is-root-path={isRootPath}>
         <header className="global-header"><div className="global-wrapper">{header}</div></header>
      
      <main className="global-wrapper">{children}</main>
      <footer className="global-footer">
        
        <div className="global-wrapper">
          <a href="https://www.gatsbyjs.com">EnglishClub_uz </a>
        {`  `}
        {new Date().getFullYear()},</div> 
      </footer>
    </div>
  )
}

export default Layout
